import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const MarketingDlaHoteliPage = ({ location: { pathname } }) => {
	if (typeof window !== `undefined`) {
		(function ($) {
			$.fn.shuffleLetters = function (prop) {
				var options = $.extend(
					{
						step: 1, // How many times should the letters be changed
						fps: 30, // Frames Per Second
						text: "", // Use this text instead of the contents
						callback: function () {}, // Run once the animation is complete
					},
					prop
				);

				return this.each(function () {
					var el = $(this),
						str = "";

					// Preventing parallel animations using a flag;
					if (el.data("animated")) {
						return true;
					}
					el.data("animated", true);

					if (options.text) {
						str = options.text.split("");
					} else {
						str = el.text().split("");
					}

					// The types array holds the type for each character;
					// Letters holds the positions of non-space characters;

					var types = [],
						letters = [];

					// Looping through all the chars of the string

					for (var i = 0; i < str.length; i++) {
						var ch = str[i];

						if (ch === " ") {
							types[i] = "space";
							continue;
						} else if (/[a-z]/.test(ch)) {
							types[i] = "lowerLetter";
						} else if (/[A-Z]/.test(ch)) {
							types[i] = "upperLetter";
						}

						letters.push(i);
					}

					el.html("");

					// Self executing named function expression:

					(function shuffle(start) {
						// This code is run options.fps times per second
						// and updates the contents of the page element

						var i,
							len = letters.length,
							strCopy = str.slice(0); // Fresh copy of the string

						if (start > len) {
							// The animation is complete. Updating the
							// flag and triggering the callback;

							el.data("animated", false);
							options.callback(el);
							return;
						}

						// All the work gets done here
						for (i = Math.max(start, 0); i < len; i++) {
							// The start argument and options.step limit
							// the characters we will be working on at once

							if (i < start + options.step) {
								// Generate a random character at thsi position
								strCopy[letters[i]] = randomChar(
									types[letters[i]]
								);
							} else {
								strCopy[letters[i]] = "";
							}
						}

						el.text(strCopy.join(""));

						setTimeout(function () {
							shuffle(start + 1);
						}, 2550 / options.fps);
					})(-options.step);
				});
			};

			function randomChar(type) {
				var pool = "";

				if (type === "lowerLetter") {
					pool = "abcdefghijklmnopqrstuvwxyz";
				} else if (type === "upperLetter") {
					pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
				}

				var arr = pool.split("");
				return arr[Math.floor(Math.random() * arr.length)];
			}
		})($);

		$(function () {
			var container = $("#shuffle");
			container.shuffleLetters();

			function shuffle(text) {
				container.shuffleLetters({
					text: text,
				});
			}

			var arr = [
				"Strategię marki",
				"Strony internetowe",
				"Google ads",
				"Facebook ads",
				"Social media",
				"Kampanie MICE",
			];
			var i = 0;
			setInterval(function () {
				shuffle(arr[i++ % arr.length]);
			}, 2000);
		});
	}
	return (
		<Layout
			seo={{
				title: "Marketing dla hoteli, hotelowe kampanie Google i Facebook Ads",
				description:
					"Zyskaj przewagę konkurencyjną z naszym kompleksowym podejściem do marketingu dla hoteli. Poznaj strategie i narzędzia, które pomogą Ci dotrzeć do nowych klientów i zwiększyć zysk swojego biznesu. Nasze doświadczenie pozwala Nam skutecznie prowadzić kampanie Google Ads oraz Facebook Ads dla hoteli. 8 lat w branży deweloperskiej.",
				href: pathname,
				lang: "pl",
			}}
		>
			<span className="anchor" id="home"></span>
			<div className="subpage-header subpage-header--single-offer">
				<h1 className="subpage-header__title">
					Marketing <br /> <span>dla hoteli</span>
				</h1>
			</div>

			<div className="single-offer-menu-container">
				<div className="row">
					<div className="col-lg-3">
						<Link to="/uslugi/#specjalizacje">
							<div className="back-wrapper">
								<span className="back-wrapper__arrow"></span>
								<p className="back-wrapper__title">Usługi</p>
							</div>
						</Link>
						<p className="nav-title">Specjalizacje:</p>
						<ul>
							<li>
								<Link to="/marketing-dla-ecommerce/">
									Marketing dla <br /> e-commerce
								</Link>
							</li>
							<li>
								<Link to="/marketing-dla-deweloperow/">
									Marketing dla deweloperów
								</Link>
							</li>
							<li>
								<Link to="/marketing-produktow-inwestycyjnych/">
									Marketing produktów <br /> inwestycyjnych
								</Link>
							</li>
							<li className="current">
								<a href="#home">Marketing dla hoteli</a>
							</li>
							{/* <li>
                <a href="/">Marketing dla gamingu</a>
              </li> */}
						</ul>
					</div>
				</div>
			</div>

			<section className="single-offer-section-text-left">
				<div className="row">
					<div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
						<div className="row align-items-center">
							<div className="col-lg-6 text-col">
								<h2 className="text-col__title mb-40">
									Partner w sukcesie
								</h2>
								<p className="text-col__desc mb-20">
									Booking, travelist, trivago - istnieje wiele
									sposobów na to, jak wygenerować obłożenie
									obiektu hotelowego bazując na wysokich,
									kilkunastoprocentowych prowizjach tych
									portali. Nasze pytanie brzmi… <br />
									Po co tak drogo? <br />
									<br />
									Twój rozwój, to nasz rozwój. Twój zysk, to
									nasz zysk. #whenwewin to nasza filozofia
									wspólnego partnerstwa w sukcesie.
									Proponujemy Ci najnowsze rozwiązania z
									dziedziny reklamy dla obiektów hotelowych,
									najbardziej aktualną wiedzę oraz pakiet
									wielu lat naszego doświadczenia w reklamie
									dla Twojej branży. <br />
									Jako agencja z najwyższą specjalizacją
									Google - Google Premier Partner, z zespołem
									5 doświadczonych specjalistów Google i
									Facebook Ads wyspecjalizowanych w reklamie
									dla obiektów hotelowych, nie pozwolimy
									Twojemu obiektowi na stagnację. <br />
									<br />
									<strong>
										Czytaj więcej o naszej filozofii:
									</strong>
								</p>
								<div className="text-wrapper--inner-action">
									<Link
										to="/win-win/"
										className="btn btn-line"
									>
										filozofia win-win
									</Link>
								</div>
							</div>
							<div className="col-lg-6 img-col">
								<img
									className="img-fluid img-col__image"
									src={require("../assets/images/marketing-dla-hoteli-img-1.jpg")}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-text-right">
				<div className="row">
					<div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
						<div className="row align-items-center">
							<div className="col-lg-6 img-col">
								<img
									className="img-fluid img-col__image"
									src={require("../assets/images/marketing-dla-hoteli-img-2.jpg")}
									alt=""
								/>
							</div>
							<div className="col-lg-6 text-col">
								<h2 className="text-col__title mb-40">
									ROI Masters
								</h2>
								<p className="text-col__desc">
									Nie będziemy tego ukrywać - mamy bzika na
									punkcie analityki, dlatego nie zatrzymujemy
									się na analizie tego co widzimy w Google
									Analytics. Nasz poziom wiedzy i
									doświadczenia w branży hotelarskiej wybiega
									znacznie poza podstawowe analizy. <br />
									<br />
									Za pomocą wspólnie tworzonych raportów
									przeanalizujesz z nami:
								</p>
								<ul className="big-bullets">
									<li>
										Jak zmiany cen, zawartości czy
										prezentacji pakietów wpłynie na wzrost
										dziennych przychodów z rezerwacji
										pobytów,
									</li>
									<li>
										sprzedaż których pakietów specjalnych
										generuje najmniejsze koszty
										marketingowe, w celu optymalizacji
										Twojego zysku,
									</li>
									<li>
										jak wygląda koszt wygenerowania
										przychodów na poszczególne okresy,
									</li>
									<li>
										i najważniejsze: o ile taniej uda się
										wygenerować przychód z rezerwacji
										bazując na optymalizacji reklam Google,
										Facebook i Youtube Ads dla Twojego
										obiektu hotelowego.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-text">
				<div className="row">
					<div className="col-lg-8 offset-lg-3 text-col">
						<h2 className="text-col__title">
							<span id="shuffle">Strategię marki</span> <br />
							<span>dla obiektów hotelowych</span>
						</h2>
					</div>
				</div>
			</section>
			<section className="single-offer-section-case-study">
				<div className="row">
					<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
						<h2 className="case-col__title">
							Sprawdź nasze przykładowe case <br /> studies z
							branży hotelarskiej
						</h2>
						<div className="post-wrapper">
							<div className="post-wrapper__thumbnail-container thumbnail-container">
								<div className="rectangle"></div>
								<img
									className="img-fluid thumbnail-container__image image"
									src={require("../assets/images/hotel-marketing-case-1.png")}
									alt=""
								/>
							</div>
							<p className="post-wrapper__category">
								branża hotelarska
							</p>
							<p className="post-wrapper__title">
								<span>86% obłożenia i 4%</span> udziału budżetu
								marketingowego w przychodach z rezerwacji
							</p>
							<p className="post-wrapper__desc">
								Dziesiątki obostrzeń, strach i jedno pytanie:
								jak to będzie z tymi wakacjami 2020?
								Odpowiadamy: bardzo dobrze! Sprawdź nasze case
								study wakacyjnej kampanii Google i Facebook Ads
								3-gwiazdkowego hotelu wypoczynkowego w dobie
								Covid-19.
							</p>
							<div className="text-wrapper--inner-action">
								<Link
									to="/case-study/wakacyjna-kampania-hotelowa/"
									className="btn btn-line"
								>
									więcej
								</Link>
							</div>
						</div>
					</div>
					<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
						<div className="post-wrapper">
							<div className="post-wrapper__thumbnail-container thumbnail-container">
								<div className="rectangle"></div>
								<img
									className="img-fluid thumbnail-container__image image"
									src={require("../assets/images/hotel-marketing-case-2.png")}
									alt=""
								/>
							</div>
							<p className="post-wrapper__category">
								branża hotelarska
							</p>
							<p className="post-wrapper__title">
								Społeczność <span>30 000</span> zaangażowanych
								fanów obiektu hotelowego Blue Mountain Resort w
								ciągu 2 lat
							</p>
							<p className="post-wrapper__desc">
								Praca nad budową społeczności od zera to nasze
								ulubione zadanie. Sprawdź nasze case study
								prowadzenia strony na Facebooku dla
								4-gwiazdkowego hotelu wypoczynkowego Blue
								Mountain Resort od pierwszego dnia istnienia
								obiektu.
							</p>
							<div className="text-wrapper--inner-action">
								<Link
									to="/case-study/blue-mountain-resort/"
									className="btn btn-line"
								>
									więcej
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-contact">
				<div className="form-wrapper">
					<h2 className="single-offer-section-contact__title mb-40">
						Skontaktuj się z nami! <br />
						Wypełnij <span>formularz</span>
					</h2>
					<ContactFormDark locales={locales["pl"]} />
				</div>
				<img
					className="img-fluid single-offer-section-contact__hand"
					src={require("../assets/images/home-contact-hand-right.png")}
					alt=""
				/>
			</section>
		</Layout>
	);
};
export default MarketingDlaHoteliPage;
